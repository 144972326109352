import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import init_wasm from "arm-simulator";
import { ElfFileProvider } from './contexts/ElfFileContext';

init_wasm().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <ElfFileProvider>
        <App />
      </ElfFileProvider>
    </React.StrictMode>
  );
})
