import React, {useEffect} from 'react';

interface HexInputProps {
    input: number,
    onChange: (value: number) => void,
    glow: boolean,
    style?: React.CSSProperties
}

const hex_regex = /^[0-9a-fA-F]*$/;

export function to_hex(num: number): string {
  if (num < 0) {
    // use >>> to "convert" to unsigned 32-bit int
    num = (0xF_0000_0000 + num) >>> 0;
  }
  return num.toString(16).padStart(8, '0');
}

export function from_hex(hex: string): number {
    return ~~parseInt(hex, 16);
}

function HexInput({input, onChange, glow, style}: HexInputProps) {
    const [value, setValue] = React.useState(to_hex(input));
    const [changed, setChanged] = React.useState(false);

    function correct(event: React.FormEvent<HTMLInputElement>) {
        let input = event.target as HTMLInputElement;
        if(hex_regex.test(input.value)) {
          setValue(input.value);
        }
    }

    function changeValueOnExit(event: React.FormEvent<HTMLInputElement>) {
      let currentValue = event.currentTarget.value.padStart(8, '0');
      setValue(currentValue);
      onChange(from_hex(currentValue));
    }

    function changeValueOnEnter(event: React.KeyboardEvent<HTMLInputElement>) {
      if(event.key === 'Enter') {
        event.currentTarget.blur();
      }
    }

    useEffect(() => {
      function handleMessage(event: MessageEvent) {
        if (event.data.type === 'state_has_changed') {
            setChanged(false);
        }
      }
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);


  useEffect(() => {
    let hex_val = to_hex(input);
    setChanged(hex_val !== value);
    setValue(hex_val);
  }, [input]);
  

  return (
    <input type="text" maxLength={8} value={value} onChange={correct} 
        size={8} className={glow && changed ? 'changed' : ''}
        onBlur={changeValueOnExit} onKeyDown={changeValueOnEnter}
        style={style}
    />
  )
}
  
export default HexInput;
  