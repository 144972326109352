import { Editor, Monaco } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { simulator, update_state } from '../App';
import './Disassembly.css'
import { useEffect, useState } from 'react';
import { useElfFile } from '../contexts/ElfFileContext';

function Disassembly() {
  let iEditor: monaco.editor.IEditor| null = null;
  let decorations: monaco.editor.IEditorDecorationsCollection | null = null;

  let lineNumberMapping = new Map<number, number>();
  let revLineNumberMapping = new Map<number, number>();
  let breakpoints: number[] = [];

  let currentLine: number | undefined;
  let markedLineNumber: number | undefined;

  function lineNumbersFunction(originalLineNumber: number): string {
    return adresses[originalLineNumber-1];
  }

  function updateLocation(address: number) {
    let lineNumber = lineNumberMapping.get(address);
    currentLine = lineNumber;
    if(lineNumber) {
      iEditor?.revealLineInCenterIfOutsideViewport(lineNumber);
    }
    updateGlyphs(markedLineNumber);
  }

  // This is not optimal, but i have tested it and it works fine even with
  // 10000+ breakpoints. It keeps the code simple for now.
  function updateGlyphs(breake: number | undefined) {
    let set: monaco.editor.IModelDeltaDecoration[] = [];

    if (currentLine) {
      set.push({
        range: new monaco.Range(currentLine, 0, currentLine, 1),
        options: {
          glyphMarginClassName: "arrow",
          isWholeLine: true,
          className: "current-instruction-background"
        },
      })
    }

    if (breake && !breakpoints.includes(breake)) {
      set.push({
        range: new monaco.Range(breake , 0, breake, 1),
        options: {
          glyphMarginClassName: (breake === currentLine ? "arrow-" : "") + "breakpoint-preview"
        },
      })
    }

    breakpoints.forEach(element => {
      set.push({
        range: new monaco.Range(element, 0, element, 1),
        options: {
          glyphMarginClassName: (element === currentLine ? "arrow-" : "") + "breakpoint"
        },
      })
    });

    decorations?.set(set);
  }

  function setup(monaco: Monaco) {
    //monaco.languages.register({ id: "armASM" });
    //monaco.languages.setMonarchTokensProvider("armASM", {
    //  ignoreCase: true,
    //	tokenizer: {
    //		root: [
    //      [/\b((?:R(?:[0-9]|1[0-5])|SP|LR|PC))\b/, "registers"],
    //      [/(#-?\d+)|(0x-?[0-9A-F]+)/, "numbers"],
    //			[/ (((LDR|STR)(?:B|SB|H|SH)?)|((LDM|STM)(?:IA|IB|DA|DB)?))(?:EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?\b/, "loadStore"],
    //			[/ (AND|EOR|SUB|RSB|ADD|ADC|SBC|RSC|TST|TEQ|CMP|CMN|ORR|MOV|BIC|MVN)S?(?:EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?\b/, "dataProcessing"],
    //			[/ BL?X?(?:EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?\b/, "branch"],
    //			[/ SWPB?(?:EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?\b/, "swap"],
    //			[/ ((MUL|MLA)S?(?:EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?)|((UMULL|UMLAL|SMULL|SMLAL)(?:EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?S?)\b/, "multiply"],
    //			[/ (SWI|BKPT|MRS|MSR|CDP|CDP2|LDC|LDC2|MCR|MCR2|MRC|MRC2|STC|STC2)\b/, "generic"],
    //			[/ (LSL|LSR|ASR|ROR|RRX)\b/, "shift"],
    //		],
    //	},
    //});

    //monaco.editor.defineTheme("armTheme", {
    //	base: "vs-dark",
    //  inherit: true,
    //	rules: [
    //		{ token: "loadStore", foreground: "3d64cf", fontStyle: "bold" },
    //		{ token: "swap", foreground: "3d64cf", fontStyle: "bold" },
    //		{ token: "dataProcessing", foreground: "6CC66C", fontStyle: "bold" },
    //		{ token: "registers", foreground: "6BAFD1" },
    //		{ token: "numbers", foreground: "FFA857"},
    //		{ token: "multiply", foreground: "21a898", fontStyle: "bold" },
    //		{ token: "branch", foreground: "8E63B7", fontStyle: "bold" },
    //		{ token: "generic", foreground: "b53d1f", fontStyle: "bold" },
    //		{ token: "shift", foreground: "d6b23b", fontStyle: "bold" }
    //	],
    //	colors: {}
    //});
  }

  function handleEditorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {
    iEditor = editor;
    editor.onMouseDown((e) => {
      // Set & Remove breakpoint
      if (e.target.type === monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN && !e.target.detail.isAfterLines) {
        if (breakpoints.includes(e.target.position.lineNumber)) {
          let lineNumber = e.target.position.lineNumber;
          breakpoints = breakpoints.filter((b) => b !== lineNumber);
          if (revLineNumberMapping.has(lineNumber)) {
            simulator?.remove_breakpoint(revLineNumberMapping.get(lineNumber) ?? 0);
          }
          updateGlyphs(lineNumber);
        }
        else {
          breakpoints.push(e.target.position.lineNumber);
          if (revLineNumberMapping.has(e.target.position.lineNumber)) {
            simulator?.add_breakpoint(revLineNumberMapping.get(e.target.position.lineNumber) ?? 0);
          }
          
          updateGlyphs(undefined);
        }
        
      }
      // Set PC
      else if (e.target.type === monaco.editor.MouseTargetType.GUTTER_LINE_NUMBERS && !e.target.detail.isAfterLines) {
        let lineNumber = e.target.position.lineNumber;
        if (revLineNumberMapping.has(lineNumber)) {
          simulator?.set_register(15, revLineNumberMapping.get(lineNumber) ?? 0);
          update_state();
        }
      }
    });

    editor.onMouseMove((e) => {
      // Show breakpoint preview
      if (e.target.type === monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN && !e.target.detail.isAfterLines) {
        let lineNumber = e.target.position.lineNumber;
          if(markedLineNumber !== lineNumber) {
            markedLineNumber = lineNumber;
            updateGlyphs(lineNumber);
          }
      }
      else {
        if (markedLineNumber !== undefined) {
          markedLineNumber = undefined;
          updateGlyphs(undefined);
        }
      }
    });

    editor.onMouseLeave((e) => {
      markedLineNumber = undefined;
      updateGlyphs(undefined);
    });

    decorations = editor.createDecorationsCollection();
    updateLocation(simulator?.get_program_counter() ?? 0);
  }


  let disassembly = "";
  try {
     disassembly = simulator?.get_disassembly().slice(0, -1) ?? " | ERROR!";
  }
  catch (error) {
    disassembly = "ERROR: | no text section found";
  }
  
  let lines = disassembly.split("\n");
  let dividedLines = lines.map((l: string) => l.split(" | "));
  let adresses = dividedLines.map((l: string[]) => l[0]);
  let code = dividedLines.map((l: string[]) => l[1]).join('\n');

  let lineCounter = 1;
  adresses.forEach((addressString: string) => {
    let address = parseInt(addressString, 16)
    if (address) {
      lineNumberMapping.set(address, lineCounter);
      revLineNumberMapping.set(lineCounter, address);
    }
    lineCounter++;
  });

  const [version, setVersion] = useState(0);

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.type === 'state_has_changed') {
        updateLocation(simulator?.get_program_counter() ?? 0);
      }
    }
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [updateLocation]);

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.type === 'disassembly_has_changed') {
        setVersion(version + 1);
      }
    }
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [version]);

  return <Editor key={version}
            height="550px"
            value={code}
            onMount={handleEditorDidMount}
            beforeMount={setup}
            theme='armTheme'
            language='armASM'
            options={{
              readOnly: true, 
              fontSize: 20,
              lineNumbers: lineNumbersFunction,
              glyphMargin: true,
              scrollBeyondLastLine: false,
              minimap: {enabled: false},
              lineNumbersMinChars: 10,
              folding: true,
              selectOnLineNumbers: false,
              occurrencesHighlight: false,
            }}
          />;
}

export default Disassembly;