import { useEffect, useRef, useState } from "react";
import { simulator, update_state } from "../App";
import UpDownButton from "./UpDownButton";
import HexInput, { to_hex } from "./HexInput";

const MIN_MEMORY_ADDRESS = 0x00000000;
const MAX_MEMORY_ADDRESS = 0x03FFFFFF;

const MEMORY_ERROR_MESSAGE = "This simulator only simulates a 26-Bit memory.\n The selected address must be between 0x" + to_hex(MIN_MEMORY_ADDRESS) + " and 0x" + to_hex(MAX_MEMORY_ADDRESS);

function Flag() {
  const [cols, setCols] = useState(1);
  const [baseAddress, setBaseAddress] = useState(0);
  const [addresses, setAddresses] = useState<number[]>(new Array(16).fill(0));
  const [memVals, setMemVals] = useState<number[]>(new Array(64).fill(0));
  const [strVals, setStrVals] = useState<String[]>(new Array(64).fill("...."));
  const observedDiv = useRef<HTMLDivElement | null>(null);

  function changeWordOnExit(address: number) {
    return (newValue: number) => {
      const byteArray = new Uint8Array(4);

      byteArray[0] = newValue & 0xFF;
      byteArray[1] = (newValue >> 8) & 0xFF;
      byteArray[2] = (newValue >> 16) & 0xFF;
      byteArray[3] = (newValue >> 24) & 0xFF;

      simulator?.set_memory(address, byteArray);
      update_state();
    };
  }

  function calcColumns(width: number) {
    console.log(width)
    if (width < 424) {
      return 1;
    }
    if (width < 735) {
      return 2;
    }
    return 4;
  }

  const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[], _: ResizeObserver) => {
    const { width } = entries[0].contentRect
    setCols(calcColumns(width));
  });


  useEffect(() => {
    if (observedDiv.current) {
      resizeObserver.observe(observedDiv.current);
    }
    return () => {
      resizeObserver.disconnect();
    }
  }, [observedDiv.current])

  function updateBaseAddress(newAddress: number) {
    newAddress = newAddress >>> 0;

    if (newAddress < MIN_MEMORY_ADDRESS) {
      newAddress = MIN_MEMORY_ADDRESS;
      alert(MEMORY_ERROR_MESSAGE);
    } else if (newAddress > MAX_MEMORY_ADDRESS) {
      newAddress = MAX_MEMORY_ADDRESS;
      alert(MEMORY_ERROR_MESSAGE);
    }

    setBaseAddress(newAddress);
  }

  function increaseBaseAddress() {
    let newAddress = baseAddress + 4 * cols;
    if (newAddress >= MAX_MEMORY_ADDRESS) {
      newAddress = MAX_MEMORY_ADDRESS;
    }
    newAddress -= (newAddress % (4 * cols));
    setBaseAddress(newAddress)
  }

  function decreaseBaseAddress() {
    let newAddress = baseAddress - 4 * cols;
    if (newAddress <= MIN_MEMORY_ADDRESS) {
      newAddress = MIN_MEMORY_ADDRESS;
    }
    newAddress -= (newAddress % (4 * cols));
    setBaseAddress(newAddress)
  }

  function max(a: number, b: number) {
    return a > b ? a : b;
  }

  function min(a: number, b: number) {
    return a < b ? a : b;
  }

  const [baseIndex, setBaseIndex] = useState(0);

  function updateValues(startAddress: number, columns: number) {
    let newMemVals: number[] = new Array(64).fill(0);
    let newStrVals: string[] = new Array(64).fill("....");

    try {
      let simVals = simulator?.get_memory(startAddress, 64 * columns);
      if (simVals && simVals ) {
        for (let i = 0, valueIdx = 0; i < 16; i++) {
          for (let j = 0; j < columns; j++, valueIdx += 4) {
            let arrayIdx = i * 4 + j;	
            newStrVals[arrayIdx] = String.fromCharCode(
              simVals[valueIdx + 0], simVals[valueIdx + 1], 
              simVals[valueIdx + 2], simVals[valueIdx + 3]
            ).replace(/[^\x20-\x7E]/g, '.');
            
            newMemVals[arrayIdx] = (simVals[valueIdx + 3] << 24) | 
                              (simVals[valueIdx + 2] << 16) | 
                              (simVals[valueIdx + 1] <<  8) | 
                              (simVals[valueIdx + 0] <<  0);
          }
        }
      }
    }
    catch (error) {
      alert(error);
    }

    setMemVals(newMemVals);
    setStrVals(newStrVals);
  }


  // Update Addresses
  useEffect(() => {
    let bytesInRow = 4 * cols;
    let x = Math.floor((baseAddress - MIN_MEMORY_ADDRESS) / bytesInRow);
    let y = Math.floor((MAX_MEMORY_ADDRESS - baseAddress) / bytesInRow);
    let baseIndex = min(max(15 - y, 7), x);

    let newAddresses = new Array(16).fill(0);
    newAddresses[baseIndex] = baseAddress - (baseAddress % (4 * cols));
    for (let i = baseIndex; i > 0; i--) {
      newAddresses[i - 1] = newAddresses[i] - 4 * cols;
    }
    for (let i = baseIndex; i < 16; i++) {
      newAddresses[i + 1] = newAddresses[i] + 4 * cols;
    }
    setBaseIndex(baseIndex);
    setAddresses(newAddresses);

    updateValues(newAddresses[0], cols);
  }, [baseAddress, cols])

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.type === 'state_has_changed') {
        updateValues(addresses[0], cols);
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [addresses, cols]);

  // Not working
  // function scrollMemory(e: React.WheelEvent<HTMLTableElement>) {
  //   if (e.deltaY < 0) {
  //     decreaseBaseAddress();
  //   } else {
  //     increaseBaseAddress();
  //   }
  // }

  return (
    <div ref={observedDiv} style={{overflow: 'hidden'}}>
      <h2>Memory</h2>
      <center>
        <table>
          <tbody>
            <tr>
              <td style={{textAlign: "right"}}>
                Location:
              </td>
              <td>
                <HexInput input={baseAddress} onChange={updateBaseAddress} glow={false}/>
              </td>
              <td style={{paddingBottom: "4px", textAlign: "left"}}>
                <UpDownButton 
                  onUp={increaseBaseAddress}
                  onDown={decreaseBaseAddress}
                /> 
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
      </center>
      <table style={{width: "100%"}}>
        <thead>
          <tr>
            <th><b>Address</b></th>
            <th><b>Content</b></th>
            <th><b>ASCII</b></th>
          </tr>
          <tr style={{height: "10px"}}><td colSpan={3}><hr/></td></tr>
        </thead>
        <tbody>
          {[...Array(16)].map((_, i) => (
            <tr key={i}>
              <td style={{paddingBottom: "10px", color: (i === baseIndex ? '#009494' : 'white')}}>
                {to_hex(addresses[i])}:
              </td>
              <td>
                {[...Array(cols)].map((_, j) => (
                  <HexInput  key={4*i + j}
                    input={memVals[4*i + j]} 
                    onChange={changeWordOnExit(addresses[i] + 4*j)} 
                    glow={false}
                    style={{margin: "0px 2px 8px 2px", textAlign: "center"}}
                  />
                ))}
              </td>
              <td style={{paddingBottom: "10px"}}>
                {[...Array(cols)].map((_, j) => (
                  <span key={4*i + j}>{strVals[4*i + j]}</span>
                ))}
              </td>
             </tr>
          ))}
          <tr style={{height: "10px"}}><td colSpan={3}><hr/></td></tr>
        </tbody>
      </table>
    </div>
  )
}

export default Flag;
  