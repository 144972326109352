import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ElfFileContextType {
  elfFile: File | undefined;
  setElfFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const ElfFileContext = createContext<ElfFileContextType | undefined>(undefined);

export function ElfFileProvider({ children }: {children: ReactNode} ) {
  const [elfFile, setElfFile] = useState<File | undefined>();

  return (
    <ElfFileContext.Provider value={{ elfFile, setElfFile }}>
      {children}
    </ElfFileContext.Provider>
  );
}

export function useElfFile() {
  const context = useContext(ElfFileContext);
  if (!context) {
    throw new Error('useSimulator must be used within a ElfFileProvider');
  }
  return context;
}