import './UpDownButton.css'

interface UpDownButtonProps {
  onUp: () => void;
  onDown: () => void;
}

function UpDownButton({ onUp, onDown }: UpDownButtonProps) {
  return (
    <div> 
      <button className="up-button" onClick={onUp}>↑</button><br/>
      <button className="down-button" onClick={onDown}>↓</button>
    </div>
  )
}

export default UpDownButton;