import { ChangeEvent, useEffect, useState } from 'react';
import './EditorPage.css';
import { Editor, Monaco } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { useElfFile } from '../contexts/ElfFileContext';
import { saveAs } from 'file-saver';

function CodeEditor() {
    const { setElfFile } = useElfFile();
    const [edit, setEdit] = useState<monaco.editor.IStandaloneCodeEditor | null>(null);
    const [templates, setTemplates] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState("none");
    const [ediorValue, setEditorValue] = useState("");
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    useEffect(() => {
        try {
          fetch("/api/templates").then(response => {
            if(response.ok) {
                response.json().then(data => {
                    if(data.templates) {
                        let templates = data.templates;
                        templates.sort((a: string, b: string) => (a === "none") ? -1 : (b === "none") ? 1 : 0)
                        setTemplates(data.templates);
                    }
                });
            }
            else {
                alert("Api error");
            }
        });
        } catch (error) {
          console.error('Error:', error);
        }
    }, []);

    useEffect(() => {
        try {
            var bodyFormData = new FormData();
            bodyFormData.append("template", currentTemplate);
          fetch("/api/template", {method: "post", body: bodyFormData}).then(response => {
            if(response.ok) {
                response.text().then(data => {
                    setEditorValue(data);
                    setHasChanged(false);
                });
            }
            else {
                alert("Api error");
            }
        });
        } catch (error) {
          console.error('Error:', error);
        }
    }, [currentTemplate]);


    function load() {
        if(edit) {
        try {
            var bodyFormData = new FormData();
            bodyFormData.append("template", currentTemplate);
            
            const code = edit.getValue();
            const blob = new Blob([code]);
            bodyFormData.append("program", blob);

          fetch("/api/assemble", {method: "post", body: bodyFormData}).then(response => {
            if(response.ok) {
                response.blob().then(data => {
                    setElfFile(new File([data], "elf"));
                });
            }
            else {
                response.text().then(data => {
                    alert(data);
                });
            }
        });
        } catch (error) {
          console.error('Error:', error);
        }
    }
    }

    function handleEditorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {
        setEdit(editor);
    }

    function setup(monaco: Monaco) {
        monaco.languages.register({ id: "armASM" });
        monaco.languages.setMonarchTokensProvider("armASM", {
            ignoreCase: true,
            directives: [
                ".align", ".globl", ".global", ".data", ".text", 
                ".ascii", ".byte", ".char", ".float", ".int", ".half", ".long", ".short", ".string", ".ubyte", ".uhalf", ".uint", ".ushort", ".ulong", ".uword", ".word"
            ],
            tokenizer: {
                root: [
                  
                    [/((R(?:[0-9]|1[0-5])|SP|LR|PC))\b/, "registers"],
                    [/(((LDR|STR)(B|SB|H|SH)?)|((LDM|STM)(IA|IB|DA|DB)?))(EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?($|\s)/, "loadStore"],
                    [/(AND|EOR|SUB|RSB|ADD|ADC|SBC|RSC|TST|TEQ|CMP|CMN|ORR|MOV|BIC|MVN)S?(EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?($|\s)/, "dataProcessing"],
                    [/BL?X?(?:EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?($|\s)/, "branch"],
                    [/SWPB?(?:EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?($|\s)/, "swap"],
                    [/((MUL|MLA)S?(EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?)|((UMULL|UMLAL|SMULL|SMLAL)(EQ|NE|HS|LO|MI|PL|VS|VC|HI|LS|GE|LT|GT|LE|AL)?S?)($|\s)/, "multiply"],
                    [/(SWI|BKPT|MRS|MSR|CDP|CDP2|LDC|LDC2|MCR|MCR2|MRC|MRC2|STC|STC2)($|\s)/, "generic"],
                    [/(LSL|LSR|ASR|ROR|RRX)($|\s)/, "shift"],
                    [/(#-?\d+)|(0x-?[0-9A-F]+)|(\d+(\.\d+)?)\b/, "numbers"],
                    [/#.*|\/\/.*/, "comment"],
                    [/\/\*/, "comment", "@comment"],
                    [/"[^"]*"/, 'string'],
                    [/[{}()\[\]]/, '@brackets'],
                    [/[/.[a-z_$][\w$]*/, { cases: {'@directives': 'keyword'} }],
                ],
                //for mline comments
                comment: [
                  [/.*?\*\//, 'comment', '@pop'],
                  [/.*$/, 'comment']
              ]
            },
        });
    
        monaco.editor.defineTheme("armTheme", {
            base: "vs-dark",
          inherit: true,
            rules: [
                { token: "loadStore", foreground: "3d64cf", fontStyle: "bold" },
                { token: "swap", foreground: "3d64cf", fontStyle: "bold" },
                { token: "dataProcessing", foreground: "6CC66C", fontStyle: "bold" },
                { token: "registers", foreground: "6BAFD1" },
                { token: "numbers", foreground: "FFA857"},
                { token: "multiply", foreground: "21a898", fontStyle: "bold" },
                { token: "branch", foreground: "8E63B7", fontStyle: "bold" },
                { token: "generic", foreground: "b53d1f", fontStyle: "bold" },
                { token: "shift", foreground: "d6b23b", fontStyle: "bold" },
                { token: "keyword", foreground: "9395d3"},
                { token: "comment", foreground: "6c757d"},
                { token: "string", foreground: "50a17c"},
            ],
            colors: {}
        });
      }

      function changeTemplate(template: string) {
        if(hasChanged) {
            if(!window.confirm("Are you sure you want to change template? All changes will be lost.")) {
                //select.target.value = currentTemplate;
                return;
            }
        }
        setCurrentTemplate(template);
      }

      function handleELFUpload(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setElfFile(e.target.files[0]);
        }
        setOpen(false);
      };

      function handleCodeUpload(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            e.target.files[0].text().then(text => {
                setEditorValue(text);
                setHasChanged(true);
            });
        }
        setOpen(false);
      };


      const [open, setOpen] = useState(false);

      const handleOpen = () => {
        setOpen(!open);
      };
    
      const handleMenu = () => {
        // do something
        setOpen(false);
      };

      function save() {
        if(edit) {
            const blob = new Blob([edit.getValue()]);
            saveAs(blob, "program.S");
        }
      }

     const  onInputClick = ( event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement
        element.value = ''
    }


    return (
    <div style={{display: "flex"}} >
      <div className='panel welcome-message' style={{minWidth: "620px"}}>
      <button onClick={save}>save</button>
            <span className="dropdown">
              <button onClick={handleOpen} style={{paddingBottom: "3px"}}>load {open ? "\u25B3": "\u25BD"}</button>
              {open ? (
                <ul className="menu">
                <li className="menu-item">
                <label 
                       htmlFor='fileinput1'
                       >
                       <span/>&nbsp;&nbsp;&nbsp;&nbsp;code&nbsp;&nbsp;&nbsp;&nbsp;
                       <input
                         type="file" 
                         id="fileinput1"
                         onChange={handleCodeUpload}
                         onClick={onInputClick}
                         hidden
                       />
                    </label>
                  </li>
                  <hr style={{margin: "0px"}}/>
                  <li className="menu-item">
                  <label 
                       htmlFor='fileinput2'
                       >
                       <span/>&nbsp;ELF-file&nbsp;
                       <input 
                         type="file" 
                         id="fileinput2"
                         onClick={onInputClick}
                         onChange={handleELFUpload}
                         hidden
                       />
                    </label>
                  </li>

                </ul>
              ) : null}
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Exercise template: &nbsp;
            <select onChange={(e) => changeTemplate(e.target.value)} value={currentTemplate}>
                {templates.map((template: string) => (
                    <option key={template} value={template}>{template}</option>
                ))}
            </select>

            <p>   </p>
          <Editor
              height="550px"
              value={ediorValue}
              onMount={handleEditorDidMount}
              beforeMount={setup}
              theme='armTheme'
              language='armASM'
              onChange={(x) => {
                setHasChanged(true);
              }}
              options={{
                fontSize: 20,
                scrollBeyondLastLine: false,
                minimap: {enabled: false},
                occurrencesHighlight: false,
                quickSuggestions: {
                    other: false,
                    comments: false,
                    strings: false
                },
              }}
            />
            <p></p>
            <button onClick={load}>assemble</button>&nbsp;
      </div>
      </div>
    )
}

//&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//<label 
//    className="button file-upload" 
//    htmlFor='fileinput'
//    >
//    <span/>upload ELF-file
//    <input 
//      type="file" 
//      id="fileinput"
//      onChange={handleFileUpload}
//      hidden
//    />
//</label>

export default CodeEditor;