import { useEffect, useState } from 'react';

interface BitInputProps {
  input: boolean,
  onChange: (value: boolean) => void
}

function Flag({input, onChange}: BitInputProps) {
  const [value, setValue] = useState(input);
  const [changed, setChanged] = useState(false);

  function onValueChange(event: React.FormEvent<HTMLSelectElement>) {
    let input = event.target as HTMLSelectElement;
    let newValue = from_string(input.value);
    setValue(newValue);
    onChange(newValue);
  }

  function to_string(value: boolean): string {
    return value ? '1' : '0';
  }

  function from_string(value: string): boolean {
    return value === '1';
  }

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.type === 'state_has_changed') {
        setChanged(false);
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
      setChanged(value !== input);
      setValue(input);
  }, [input]);

  return (
    <select value={to_string(value)} 
            className={changed ? 'changed' : ''} 
            onChange={onValueChange}>
      <option value="0">0</option>
      <option value="1">1</option>
    </select>
  )
}
  
export default Flag;
  