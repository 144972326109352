import './SimulationPage.css'
import ControlBar from '../components/ControlBar';
import Console from '../components/Console';
import Disassembly from '../components/Disassembly';
import State from '../components/State';
import Memory from '../components/Memory';

function Simulation() {
  return (
    <div className='simulation-page float-container'> 
        <div className='panel code-state-panel'>
            <ControlBar/>
            <br/>
            <div className='float-container'>
                <div style={{width: "65%"}}>
                  <Disassembly/>
                </div>
                <div className='state'>
                  <State/>
                </div>
            </div>
            <br/>
            <Console />
        </div>
        <div className='panel memory-panel'>
          <Memory />
        </div>
    </div>
  )
}

export default Simulation;