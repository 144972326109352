import './Console.css'

// This is a bottleneck
export function print(msg: string) {
  let text = document.getElementById("output") as HTMLInputElement;
  if(text) {
    let total = (text.value + msg).split("\n");
    total = total.slice(-100);
    text.value = total.join("\n");
  }
  text.scrollTop = text.scrollHeight;
}

export function print_error(err: string) {
  let text = document.getElementById("output") as HTMLInputElement;
  if(text) text.value += err;
}

export function clear_console() {
  let text = document.getElementById("output") as HTMLInputElement;
  if(text) text.value = "";
}

function Console() {
  return <textarea className="console" id="output" rows={12} readOnly></textarea>
}

export default Console;